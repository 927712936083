@import url("https://fonts.googleapis.com/css2?family=Metal+Mania&display=swap");

$xl: 1200px;
$lg: 992px;
$md: 768px;
$sm: 576px;
$xs: 400px;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#home {
  .card-img-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;

    .cards {
      width: 100%;

      .card {
        position: absolute;
        width: 25vw;
        max-width: 150px;
        &:first-child {
          transform: translateX(-100%) translateY(25px) rotate(-30deg);
        }
        &:nth-child(2) {
          transform: translateX(-50%) translateY(5px) rotate(-15deg);
        }
        &:nth-child(3) {
          position: relative;
        }
        &:nth-child(4) {
          transform: translateX(-50%) translateY(5px) rotate(15deg);
        }
        &:nth-child(5) {
          transform: translateX(0) translateY(25px) rotate(30deg);
        }
        @media (max-width: $lg) {
          max-width: 120px;
        }

        @media (max-width: $md) {
          max-width: 100px;
        }
      }
    }
    .card-img-tittle {
      font-family: Metal Mania;
      font-style: normal;
      font-weight: normal;
      font-size: 100px;
      line-height: 124px;
      padding: 100px 0px;
      width: 100vw;
      background: -webkit-linear-gradient(
        rgba(223, 223, 223, 1),
        rgba(223, 223, 223, 0)
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      @media (max-width: $lg) {
        font-size: 80px;
      }
      @media (max-width: $md) {
        font-size: 60px;
      }
    }
    .wax-cloud-wallet {
      border: 1px solid white;
      border-radius: 15px;
      padding: 15px;
      margin: auto;
      cursor: pointer;
      transition: all 0.5s ease-out;
      position: relative;
      overflow: hidden;
      width: 225px;
      display: inline-block;

      i {
        font-family: "Font Awesome 5 Free";
        content: "\f054";
        font-weight: 900;
        margin-left: auto;
      }
      img {
        width: 50px;
        height: 20px;
        background: url("./assets/images/waxlogo.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        margin-right: auto;
        position: relative;
      }
      &::before {
        content: "";
        position: absolute;
        width: 0px;
        height: 5%;
        background: #f78e1e;
        border-radius: 15px;
        margin: auto;
        transition: all 0.5s cubic-bezier(0.68, -0.1, 0, 1.84);
        z-index: -1;
        top: 22px;
        left: 50px;
        transform: rotate(-45deg);
        transform-origin: center;
        opacity: 0;
      }
      &:hover::before {
        width: 100%;
        height: 100%;
        transform: rotate(0);
        top: 0;
        left: 0;
        opacity: 1;
      }
      &:hover .link {
        color: black;
      }
      &:hover i {
        color: black;
      }

      .link {
        color: white;
        font-size: 1.4rem;
        font-weight: bold;
        text-decoration: none;
        transition: all 0.5s;
      }
    }
  }
}

#main-page {
  margin: auto;
  .mainpage-tittle {
    text-align: center;
    padding: 15px;
    font-family: Metal Mania;
    font-size: 64px;
    background: -webkit-linear-gradient(
      rgba(223, 223, 223, 1),
      rgba(223, 223, 223, 0)
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    @media (max-width: $md) {
      font-size: 48px;
    }
  }
  .mainpage-navbar {
    display: flex;
    align-items: center;
    padding: 0 50px;
    @media (max-width: $md) {
      padding: 5px;
    }
    ul {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;
      li {
        list-style: none;
        padding: 10px 30px;
        border-left: 1px solid rgba(174, 160, 160, 1);
        @media (max-width: $md) {
          padding: 5px 15px;
        }
        @media (max-width: $xs) {
          padding: 5px;
        }
        &:first-child {
          border: none;
        }
        a {
          color: white;
          text-decoration: none;
          font-size: 24px;
          font-weight: 500;
          transition: all 0.2s ease-out;

          @media (max-width: $md) {
            font-size: 20px;
          }
          @media (max-width: $xs) {
            font-size: 18px;
          }
          i {
            margin-right: 10px;
          }
          &:hover {
            color: #f78e1e;
          }
        }
      }
    }
  }
  .mainpage-game-container {
    display: flex;
    align-items: center;
    padding: 0px 5%;
    justify-content: center;
    color: #aea0a0;
    @media (max-width: $md) {
      justify-content: space-between;
    }
    .mainpage-game-left-side {
      text-align: center;
      width: 40%;
      .game-box {
        width: 174px;
        height: 234px;
        display: inline-flex;
        flex-direction: column;
        text-align: center;
        border: 1px solid #aea0a0;
        border-radius: 15px;
        margin: 20px 30px;
        justify-content: center;
        align-items: center;
        position: relative;
        @media (max-width: $md) {
          width: 100px;
          height: 134px;
        }
        .game-box-add-icon {
          width: 40px;
          height: 40px;
          border: 1px solid #aea0a0;
          border-radius: 50%;
          line-height: 40px;
          cursor: pointer;
          position: relative;
          transition: all 0.5s ease-out;
          @media (max-width: $md) {
            width: 30px;
            height: 30px;
            line-height: 30px;
          }
          i {
            transition: all 0.5s ease-out;
            transform: rotate(0deg);
          }
          &:hover i {
            transform: rotate(-180deg);
            color: white;
          }
          &:hover {
            background: #f78e1e;
            border: 1px solid transparent;
          }
          &::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            border: 1px solid #aea0a0;
            top: 0;
            left: 0;
            border-radius: 50%;
            transition: all 0.3s ease-out;
          }
          &:hover::before {
            transform: scale(1.5);
          }
        }
        .game-box-title {
          font-size: 24px;
          padding: 15px 0;
          @media (max-width: $md) {
            font-size: 18px;
          }
        }
      }
    }
    .left-side-button-container {
      display: flex;
      justify-content: center;
      align-items: center;
      button {
        width: 170px;
        height: 45px;
        background: linear-gradient(
          180deg,
          #c4c4c4 11.41%,
          rgba(196, 196, 196, 0) 100%
        );
        border-radius: 10px;
        outline: none;
        cursor: pointer;
        mix-blend-mode: normal;
        backdrop-filter: blur(4px);
        font-weight: bold;
        font-size: 18px;
        color: rgba(0, 0, 0, 0.76);
        transition: all 0.3s ease-out;
        margin: 5px 25px;
        @media (max-width: $lg) {
          font-size: 18px;
          margin: 5px 10px;
        }
        @media (max-width: $md) {
          font-size: 14px;
        }
        @media (max-width: $xs) {
          font-size: 12px;
        }

        &:hover {
          color: #f78e1e;
        }
      }
    }
    .left-side-fee-text {
      font-size: 18px;
      padding: 10px;
      span {
        padding: 0 5px;
        &:first-child {
          text-decoration: underline;
        }
        &:nth-of-type(2) {
          color: white;
          font-weight: bold;
          padding-right: 0;
        }
        &:last-child {
          padding-left: 0;
        }
      }
    }
  }
  .mianpage-game-vs-container {
    width: 20%;
    text-align: center;
    font-weight: bold;
    font-size: 24px;
    position: relative;
    height: 100px;
    line-height: 100px;
    background: black;
    @media (max-width: $md) {
      font-size: 18px;
      width: 5%;
      height: 100px;
      line-height: 100px;
      top: -50px;
    }
    @media (max-width: $xs) {
      font-size: 16px;
      width: 5%;
      height: 50px;
      line-height: 50px;
      top: -50px;
    }
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 1px;
      height: 600px;
      transform: translate(-50%, -50%);
      background: #aea0a0;
      z-index: -1;
      @media (max-width: $md) {
        height: 250px;
      }
      @media (max-width: $xs) {
        height: 300px;
      }
    }
  }
  .mainpage-game-right-side {
    width: 40%;
    text-align: center;
    font-weight: bold;
    font-size: 24px;

    @media (max-width: $md) {
      font-size: 18px;
      padding: 10px 0px;
    }
    @media (max-width: $xs) {
      font-size: 16px;
      padding: 10px 0px;
    }
  }
}

.backdrop {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 200;
  position: fixed;
  top: 0;
  left: 0;
}

.add-icon-modal {
  width: 670px;
  height: 320px;
  position: absolute;
  z-index: 300;
  top: 50%;
  left: 50%;
  background: linear-gradient(
    180deg,
    rgb(174, 160, 160) 14.02%,
    rgb(174, 160, 160) 100%
  );
  border: 1px solid #aea0a0;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  transition: all 0.5s ease-out;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: $lg) {
    width: 620px;
    height: 280px;
  }
  @media (max-width: $md) {
    width: 250px;
    height: 280px;
  }
  @media (max-width: $xs) {
    width: 230px;
    height: 280px;
  }
}
.slider-card-container {
  width: 167px;
  height: 270px;
  background: #000000;
  border: 1px solid #aea0a0;
  box-sizing: border-box;
  border-radius: 5px;
  cursor: pointer;
  @media (max-width: $lg) {
    width: 150px;
  }
  @media (max-width: $md) {
    width: 150px;
    height: 100%;
  }
  @media (max-width: $xs) {
    width: 150px;
    height: 100%;
  }
  .slider-card-img img {
    width: 90%;
    margin-top: 5px;
  }
  .slider-card-title {
    font-weight: bold;
    font-size: 15px;
    border: 1px solid #aea0a0;
    padding: 5px 0;
  }
  .slider-card-desc {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 13px;
    padding: 0 10px;
  }
}
.lgksPe {
  background: #f78e1e !important;
}

#wallet {
  margin: auto;
  .wallet-tittle {
    text-align: center;
    padding: 40px 0px;
    font-family: Metal Mania;
    font-size: 64px;
    background: -webkit-linear-gradient(
      rgba(223, 223, 223, 1),
      rgba(223, 223, 223, 0)
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    @media (max-width: $md) {
      font-size: 48px;
    }
    @media (max-width: $sm) {
      font-size: 36px;
      padding: 30px 0;
    }
  }
  .wallet-container {
    background: rgba(229, 229, 229, 0.9);
    border: 1px solid #000000;
    backdrop-filter: blur(4px);
    border-radius: 50px;
    width: 90%;
    margin: auto;
    padding-bottom: 50px;
    height: 650px;
    position: relative;

    .wallet-heading {
      font-size: 34px;
      color: black;
      text-align: center;
      text-decoration-line: underline;
      font-weight: bold;
      line-height: 50px;
      padding: 20px;
      @media (max-width: $md) {
        font-size: 24px;
      }
      @media (max-width: $sm) {
        font-size: 20px;
        padding: 0;
      }
    }
    .wallet-cards-container {
      padding: 0 50px;
      display: flex;
      justify-content: center;
      height: 75%;
      @media (max-width: $md) {
        flex-direction: column;
        justify-content: flex-start;
      }
      @media (max-width: $sm) {
        flex-direction: column;
        justify-content: flex-start;
        padding: 0;
      }

      .wallet-card-left-side {
        width: 40%;
        @media (max-width: $lg) {
          height: 85%;
          width: 100%;
        }
        @media (max-width: $md) {
          width: 100%;
          height: 35%;
        }
        @media (max-width: $sm) {
          width: 100%;
          height: 25%;
        }
        @media (max-width: $xs) {
          width: 100%;
          height: 20%;
        }
        .wallet-user {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-left: 10%;
          padding: 20px 0px;

          a {
            font-weight: bold;
            font-size: 24px;
            color: black;
            text-decoration: none;

            @media (max-width: $lg) {
              font-size: 18px;
            }
            i {
              margin-right: 10px;
            }
          }
          span {
            font-weight: 500;
            font-size: 16px;
            text-decoration-line: underline;
            color: black;
            margin-right: 50px;
            cursor: pointer;
          }
        }
        .wallet-data-container {
          overflow: auto;
          width: 100%;
          height: 86%;
          &::-webkit-scrollbar {
            width: 10px;
          }
          &::-webkit-scrollbar-track {
            background: transparent;
          }
          &::-webkit-scrollbar-thumb {
            background: #888;
            border-radius: 10px;
          }
          &::-webkit-scrollbar-thumb:hover {
            background: #555;
          }
        }
      }
      .wallet-card-data-container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 10px;
        padding-left: 10px;

        img {
          width: 10%;
          height: 10%;
          border: 1px solid #aea0a0;
          border-radius: 10px;
          margin-left: 5%;
        }
        .wallet-card-qty {
          font-weight: bold;
          font-size: 20px;
          color: black;
          margin-left: 10px;
          @media (max-width: $lg) {
            font-size: 16px;
            font-weight: bold;
          }
          @media (max-width: $md) {
            font-size: 16px;
            font-weight: normal;
          }
        }
        .wallet-card-title {
          font-weight: bold;
          font-size: 20px;
          color: black;
          margin-left: 10px;
          @media (max-width: $lg) {
            font-size: 16px;
          }
          @media (max-width: $md) {
            font-size: 16px;
            font-weight: normal;
          }
        }
      }
      .wallet-card-controlers-container {
        width: 10%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        @media (max-width: $md) {
          flex-direction: row;
          width: 100%;
          margin-top: 50px;
        }
        @media (max-width: $sm) {
          flex-direction: row;
          width: 100%;
          margin-top: 75px;
        }
        .controler-btn {
          text-align: center;
          margin: 20px 0px;
          @media (max-width: $md) {
            margin: 0 30px;
          }
          button {
            background: none;
            outline: none;
            border: none;

            cursor: pointer;
            i {
              font-size: 36px;
              transition: all 0.5s ease-out;
              @media (max-width: $lg) {
                font-size: 30px;
              }
              @media (max-width: $md) {
                font-size: 28px;
                transform: rotate(90deg);
              }
              &:hover {
                color: #f78e1e;
              }
            }
            .play-rotate {
              transform: rotate(180deg);
              @media (max-width: $md) {
                font-size: 28px;
                transform: rotate(150deg);
              }
            }
          }
          p {
            font-size: 16px;
            font-weight: bold;
            color: black;
            @media (max-width: $lg) {
              font-size: 14px;
              font-weight: bold;
            }
            @media (max-width: $md) {
              font-size: 14px;
              font-weight: normal;
            }
          }
        }
      }
    }

    .wallet-button-container {
      text-align: center;
      button {
        width: 170px;
        height: 47px;
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0.57) 11.41%,
          rgba(196, 196, 196, 0) 100%
        );
        border-radius: 10px;
        outline: none;
        cursor: pointer;
        mix-blend-mode: normal;
        backdrop-filter: blur(4px);
        font-weight: bold;
        font-size: 20px;
        color: rgba(0, 0, 0, 0.76);
        transition: all 0.3s ease-out;
        margin: 20px;

        @media (max-width: $md) {
          margin: 10px;
        }
        @media (max-width: $sm) {
          width: 120px;
          height: 40px;
          margin: 30px;
        }
        @media (max-width: $xs) {
          height: 30px;
          margin: 30px;
        }

        a {
          font-weight: bold;
          font-size: 20px;
          color: rgba(0, 0, 0, 0.76);
          transition: all 0.5s ease-out;
          @media (max-width: $lg) {
            font-size: 18px;
          }
          @media (max-width: $sm) {
            font-size: 16px;
          }
          @media (max-width: $xs) {
            font-size: 14px;
          }
        }
        &:hover a {
          color: #f78e1e;
        }
      }
    }
    .wallet-info-container {
      color: black;
      font-size: 18px;
      text-align: center;
      margin-top: -15px;
      @media (max-width: $lg) {
        margin-top: 0;
        width: 85%;
        margin: -10px auto;
        font-size: 16px;
      }
      @media (max-width: $md) {
        margin-top: 0;
        width: 80%;
        margin: auto;
        font-size: 16px;
      }
      @media (max-width: $sm) {
        width: 80%;
        font-size: 12px;
        margin-top: -30px;
      }

      p {
        position: relative;
        display: inline-block;
        @media (max-width: $md) {
          text-align: left;
          padding-left: 25px;
        }
        &::before {
          content: "i";
          position: absolute;
          left: -5%;
          top: -2px;
          width: 25px;
          height: 25px;
          line-height: 25px;
          border: 2px solid #323232;
          border-radius: 50%;
          color: #323232;
          @media (max-width: $md) {
            top: 50%;
            transform: translateY(-50%);
            text-align: center;
          }
          @media (max-width: $sm) {
            top: 25%;
            transform: translateY(-50%);
            text-align: center;
          }
        }
      }
    }
    .wallet-cross-btn {
      position: absolute;
      top: 3%;
      left: 2%;
      font-size: 36px;
      cursor: pointer;
      @media (max-width: $md) {
        font-size: 28px;
        top: 2%;
        left: 5%;
      }
      transition: all 0.5s ease-out;
      i {
        transition: all 0.5s ease-out;
        transform: rotate(0deg);
        color: black;
      }
      &:hover i {
        transform: rotate(-180deg);
        color: #f78e1e;
      }
    }
  }
}

#rules {
  overflow-x: hidden;
  margin: auto;
  .wallet-tittle {
    text-align: center;
    padding: 40px 0px;
    font-family: Metal Mania;
    font-size: 64px;
    background: -webkit-linear-gradient(
      rgba(223, 223, 223, 1),
      rgba(223, 223, 223, 0)
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    @media (max-width: $md) {
      font-size: 48px;
    }
    @media (max-width: $sm) {
      font-size: 36px;
      padding: 30px 0;
    }
  }
  .wallet-container {
    background: rgba(229, 229, 229, 0.9);
    border: 1px solid #000000;
    backdrop-filter: blur(4px);
    border-radius: 50px;
    width: 90%;
    margin: auto;
    padding-bottom: 50px;
    position: relative;
    margin-top: -10px;

    .wallet-heading {
      font-size: 34px;
      color: black;
      text-align: center;
      text-decoration-line: underline;
      font-weight: bold;
      line-height: 50px;
      padding: 20px;
      @media (max-width: $sm) {
        font-size: 24px;
        padding: 0px;
      }
    }
    .wallet-cards-container {
      padding: 0 100px;
      display: flex;
      justify-content: flex-start;
      color: black;
      height: 75%;
      overflow: auto;
      flex-direction: column;
      overflow: auto;
      height: 80%;
      &::-webkit-scrollbar {
        width: 10px;
      }
      &::-webkit-scrollbar-track {
        background: transparent;
      }
      &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
      @media (max-width: $md) {
        padding: 20px;
      }
      @media (max-width: $sm) {
        height: 85%;
      }
      h3 {
        text-decoration: underline;
        margin-bottom: 10px;
        font-size: 24px;
        @media (max-width: $md) {
          font-size: 20px;
        }
        @media (max-width: $sm) {
          font-size: 18px;
        }
      }
      p {
        font-size: 24px;
        margin-bottom: 50px;
        @media (max-width: $md) {
          font-size: 20px;
        }
        @media (max-width: $sm) {
          font-size: 16px;
          margin-bottom: 10px;
        }
      }
    }

    .wallet-button-container {
      text-align: center;

      button {
        width: 170px;
        height: 47px;
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0.57) 11.41%,
          rgba(196, 196, 196, 0) 100%
        );
        border-radius: 10px;
        outline: none;
        cursor: pointer;
        mix-blend-mode: normal;
        backdrop-filter: blur(4px);
        font-weight: bold;
        font-size: 20px;
        color: rgba(0, 0, 0, 0.76);
        transition: all 0.3s ease-out;
        @media (max-width: $md) {
          margin: 0;
        }
        @media (max-width: $sm) {
          width: 120px;
          height: 40px;
          margin-top: 10px;
        }

        a {
          font-weight: bold;
          font-size: 20px;
          color: rgba(0, 0, 0, 0.76);
          transition: all 0.5s ease-out;
          @media (max-width: $md) {
            font-size: 18px;
          }
          @media (max-width: $sm) {
            font-size: 16px;
          }
          &:hover {
            color: #f78e1e;
          }
        }
      }
    }
    .wallet-info-container {
      color: black;
      font-size: 18px;
      text-align: center;
      margin-top: -15px;
      p {
        position: relative;
        display: inline-block;
        &::before {
          content: "i";
          position: absolute;
          left: -5%;
          top: -2px;
          width: 25px;
          height: 25px;
          line-height: 25px;
          border: 2px solid #323232;
          border-radius: 50%;
          color: #323232;
        }
      }
    }
    .wallet-cross-btn {
      position: absolute;
      top: 3%;
      left: 2%;
      font-size: 36px;
      cursor: pointer;
      transition: all 0.5s ease-out;
      @media (max-width: $md) {
        font-size: 28px;
        top: 2%;
        left: 5%;
      }
      i {
        transition: all 0.5s ease-out;
        transform: rotate(0deg);
        color: black;
      }
      &:hover i {
        transform: rotate(-180deg);
        color: #f78e1e;
      }
    }
  }
}

.selected-card-img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 10px;
  pointer-events: none;
}
.fee-modal {
  position: absolute;
  top: 50%;
  left: 35%;
  width: 30%;
  height: 20%;
  z-index: 300;
  transition: all 0.5s ease-out;
  background: rgba(229, 229, 229, 0.9);
  border-radius: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: $lg) {
    top: 45%;
    left: 15%;
    width: 70%;
  }
  @media (max-width: $md) {
    top: 30%;
    left: 20%;
    width: 70%;
  }
  .wax-wallet-icon-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30%;
    img {
      width: 50px;
      height: 50px;
      @media (max-width: $md) {
        width: 40px;
        height: 40px;
      }
      @media (max-width: $sm) {
        width: 30px;
        height: 30px;
      }
    }
    i {
      font-size: 30px;
      color: #323232;
      @media (max-width: $md) {
        font-size: 24px;
      }
      @media (max-width: $sm) {
        font-size: 18px;
      }
      &:nth-of-type(1) {
        animation: transitionArrow 0.5s infinite
          cubic-bezier(0.68, -0.1, 0, 1.84);
        @keyframes transitionArrow {
          0% {
            transform: translateX(0px);
          }
          100% {
            transform: translateX(10px);
          }
        }
      }
      &:nth-of-type(2) {
        font-size: 42px;
        color: black;
        @media (max-width: $md) {
          font-size: 36px;
        }
        @media (max-width: $md) {
          font-size: 24px;
        }
      }
    }
  }
  .fee-modal-title {
    font-weight: bold;
    font-size: 24px;
    color: black;
    margin-top: 10px;
    @media (max-width: $md) {
      font-size: 20px;
    }
  }
}
.factor-icon {
  color: black;
  width: 40px;
  height: 40px;
  border: 1px solid black;
  font-weight: bold;
  font-size: 24px;
  line-height: 35px;
  border-radius: 50%;
}
.factor-icon-line {
  width: 5px;
  height: 100%;
  background: black;
  border-radius: 10px;
  animation: factorLine 0.5s infinite cubic-bezier(0.68, -0.1, 0, 1.84);
  @keyframes factorLine {
    0% {
      transform: translateY(5px);
    }
    100% {
      transform: translateY(-5px);
    }
  }
}
.won-modal {
  height: auto;
  text-align: center;
  color: black;
  position: absolute;
  top: 30%;
  left: 35%;
  width: 30%;
  z-index: 300;
  transition: all 0.5s ease-out;
  background: rgba(229, 229, 229, 0.9);
  border-radius: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 10px;
  @media (max-width: $lg) {
    top: 20%;
    left: 25%;
    width: 50%;
  }
  @media (max-width: $md) {
    top: 20%;
    left: 20%;
    width: 70%;
  }
  .won-modal-title {
    font-weight: bold;
    font-size: 25px;
    text-decoration-line: underline;
    padding: 30px 0 20px;
  }
  .won-modal-card-container {
    .won-modal-card-img {
      width: 50%;
      height: 60%;
      margin: auto;
      img {
        width: 100%;
        height: 100%;
        border-radius: 10px;
      }
    }
    .won-modal-card-title {
      font-weight: bold;
      font-size: 25px;
      padding: 10px;
    }
  }
  .won-modal-decs {
    font-weight: 500;
    font-size: 20px;
    text-decoration-line: underline;
  }
  .won-modal-btn {
    padding: 10px 20px !important;
    margin: 20px auto !important;
    background: black !important;
    mix-blend-mode: normal !important;
    border: 1px solid black !important;
    box-sizing: border-box !important;
    backdrop-filter: blur(4px) !important;
    border-radius: 10px !important;
    font-weight: bold !important;
    font-size: 20px !important;
    color: #aea0a0 !important;
    outline: none !important;
    transition: all 0.5s ease-out !important;
    @media (max-width: $lg) {
      font-size: 20px !important;
    }
    @media (max-width: $md) {
      font-size: 18px !important;
    }
    cursor: pointer;
    &:hover {
      color: #f78e1e !important;
    }
  }
}

.error-modal-title {
  font-size: 20px;
  padding: 30px;
}
.error-modal-desc {
  font-size: 16px;
  width: 70%;
  margin: auto;
  position: relative;
}
.error-modal-desc::before {
  content: "i";
  position: absolute;
  top: 0;
  left: -25px;
  border: 1px solid #555;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  line-height: 20px;
}

.card-type-text {
  color: white;
  background-color: black;
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 20px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

.animation-factor-a {
  animation: factorA 1s infinite;
  @keyframes factorA {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}

.animation-factor-b {
  animation: factorB 1s infinite;
  @keyframes factorB {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

.loader {
  width: 50px;
  height: 50px;
  border: 5px solid #aea0a0;
  border-right: 5px dotted #555;
  margin: auto;
  margin-top: 10%;
  border-radius: 50%;
  animation: loader 1.2s linear infinite;

  @keyframes loader {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
